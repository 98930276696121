import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BlogThumb from './components/BlogThumb/BlogThumb';

const FEATURED_COUNT = 3;

const BlogPreview = () => {
  let { allBlogPost: blogPosts } = useStaticQuery(graphql`
      query {
        allBlogPost {
          edges {
            node {
              id
              title
              slug
              description
              datePublished
              thumbnailUrl
              thumbnailImg {
                childImageSharp {
                  fluid(sizes: """
                    (max-width: 250px) 200px,
                    (max-width: 450px) 400px,
                    1200px""", 
                    quality: 95, fit: COVER
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `) || [];

  blogPosts = blogPosts.edges.map(p => p.node);
  blogPosts.sort((a, b) => (a.datePublished > b.datePublished) ? -1 : ((b.datePublished > a.datePublished) ? 1 : 0));
  const featuredPosts = Array.isArray(blogPosts) && (blogPosts.length > FEATURED_COUNT) ? blogPosts.slice(0, FEATURED_COUNT) : blogPosts;
  return (
    <>
      {
        Array.isArray(featuredPosts) && !featuredPosts.length
          ? <h4>Coming soon...</h4>
          : (featuredPosts || []).map(
            post => <BlogThumb key={post.id} post={post} />,
          )
      }
    </>
  );
};

export default BlogPreview;
