import ReactGA from 'react-ga';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const pageview = (pageName, title) => ReactGA.pageview(pageName, title);
const event = {
  message: action => trackCustomEvent({
    category: 'Message',
    action,
  }),
};

export default {
  pageview,
  event,
};
