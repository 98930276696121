import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';


function BlogThumb(props) {
  const { post } = props;
  const url = `/blog/${post.slug}/`;

  return (
    <div className="col-lg-4 col-md-9 col-sm-10 col-12 px-2 mb-5">
      <div className="blog-thumb">
        <div className="blog-thumb-content">
          <Link to={url}>
            <Img
              className="blog-thumb-image"
              fluid={post.thumbnailImg.childImageSharp.fluid}
              fadeIn={false}
              alt={post.title}
            />
          </Link>

          <Link className="primary-link" to={url}><h3>{post.title}</h3></Link>
          <p>{post.description}</p>

        </div>

      </div>
    </div>
  );
}

export default BlogThumb;
