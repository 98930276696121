import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image";
import Slider from "react-slick";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../css/styles.scss';
import SEO from '../components/SEO';
import Layout from '../js/components/Layout';

import ContactForm from "../js/screens/HomeScreen/components/ContactForm/ContactForm";
import PortfolioView from "../js/screens/HomeScreen/components/PortfolioView/PortfolioView";
import BlogPreview from "../js/screens/HomeScreen/components/BlogPreview/BlogPreview";


class HomePage extends React.Component {
  state = {
    isLoaded: false,
    isNavbarTransparent: true,
  };

  handleLoaded = () => {
    this.setState(() => ({ isLoaded: true }));
  };

  handleScroll = (event) => {

    this.setState({
      isNavbarTransparent: window.scrollY === 0,
    });
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  render = () => {
    const { isLoaded, isNavbarTransparent } = this.state;
    const loadingScreenClass = !isLoaded ? "loading-screen" : 'loading-screen loaded';
    const imageContainerClass = `hero-img-container ${isLoaded ? 'loaded' : ''}`;

    const titleTextClass = `title-text title-text-lg ${isLoaded ? 'loaded' : ''}`;

    const { data } = this.props;

    return (
      <>
        <div className={loadingScreenClass} />
        <Layout transparent={isNavbarTransparent} hidden={!isLoaded} floatingNavbar animateBrand>

          <SEO />


          {/* HERO SECTION */}
          <div className="row hero">
            <Slider
              className="carousel"
              infinite
              fade
              pauseOnDotsHover={false}
              pauseOnFocus={false}
              pauseOnHover={false}
              arrows={false}
              autoplay
              centerPadding={0}
              speed={1500}
              autoplaySpeed={6000}
              slidesToShow={1}
              slidesToScroll={1}
            >
              <div className="hero-slide">
                <Img
                  className={imageContainerClass}
                  style={{}}
                  imgStyle={{
                    transition: 'transform 1.5s ease-out',
                  }}
                  fluid={[
                    data.coverImage2Mobile.childImageSharp.fluid,
                    {
                      ...data.coverImage2Desktop.childImageSharp.fluid,
                      media: `(min-width: 600px)`,
                    },
                  ]}
                  loading="eager"
                  fadeIn={false}
                  alt="Overhead shot of a small backyard with garden chairs and stairs."
                  onLoad={() => this.handleLoaded()}
                  onError={() => this.handleLoaded()}
                />
                <div className="title-container">
                  <h1 className={titleTextClass}>Green Culture Studio</h1>
                  <h2 className={titleTextClass}>Landscape Design</h2>
                </div>
              </div>
              <div className="hero-slide">
                <Img
                  className={imageContainerClass}
                  fluid={[
                    data.coverImage0Mobile.childImageSharp.fluid,
                    {
                      ...data.coverImage0Desktop.childImageSharp.fluid,
                      media: `(min-width: 600px)`,
                    },
                  ]}
                  fadeIn={false}
                  alt="Aerial shot of a neighborhood with colorful trees and gardens."
                />
              </div>
              <div className="hero-slide">
                <Img
                  className={imageContainerClass}
                  fluid={[
                    data.coverImage1Mobile.childImageSharp.fluid,
                    {
                      ...data.coverImage1Desktop.childImageSharp.fluid,
                      media: `(min-width: 600px)`,
                    },
                  ]}
                  fadeIn={false}
                  alt="House with a pool surrounded by tropical trees and plants."
                />
              </div>
            </Slider>
          </div>

          {/* WELCOME SECTION */}
          <div id="welcome" className="row section section-dark justify-content-center welcome-container">
            <div className="col-sm-10 col-md-4 col-lg-3 px-2 welcome-h-container">
              <h2>Welcome</h2>
            </div>
            <div className="col-sm-10 col-md-4 col-lg-4 px-2">
              <p>Our passion is to create thoughtful designs for outdoor spaces that reflect my expertise and your unique lifestyle. With our help, you can convert all your ideas into a successful landscape project.</p>
            </div>

          </div>

          {/* PORTFOLIO SECTION */}
          <div id="portfolio" className="row section justify-content-center portfolio-container">
            <div className="col-12 col-sm-10">
              <h2 className="lined-header">Latest Projects</h2>
            </div>
            <PortfolioView />
            <div className="col-12 col-sm-10 mt-5 d-flex justify-content-center">
              <Link to='/portfolio/' className="primary-button">View More</Link>
            </div>
          </div>

          {/* BLOG SECTION */}
          <div id="blog" className="row section justify-content-center blog-preview-container section-responsive-padding">
            <div className="col-12 col-sm-10">
              <h2 className="lined-header">Blog</h2>
            </div>
            <BlogPreview />
            <div className="col-12 col-sm-10 mt-2 d-flex justify-content-center">
              <Link to='/blog/' className="primary-button">View More</Link>
            </div>
          </div>

          {/* ABOUT ME SECTION */}
          <div id="about-me" className="row section justify-content-center about-me-container section-responsive-padding">
            {/* <div className="col-12 col-sm-10">
              <h2 className="lined-header">About Me</h2>
            </div> */}
            <div className="col-12 col-sm-10">
              <h2 className="lined-header">About Us</h2>
            </div>
            <div className="col-12 col-sm-10 col-md-5 col-lg-4 col-xl-3 px-2 py-3">
              <Img
                className="about-me-img"
                style={{}}
                fluid={data.profileImg.childImageSharp.fluid}
                fadeIn={false}
                alt="milica pandurovic freelance landscape architect"
              />
              {/* <img src={imgAboutMe} className="about-me-img " /> */}

            </div>
            <div className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-5 px-2 py-3 about-me-description">
              <div>
              <h3>Milica Pandurovic, CEO at Green Culture Studio</h3>
              <p>
                &quot;I am a landscape architect who believes that innovative design
                and a holistic approach can restore the balance between nature and
                human needs.
</p>
              <p>
                Since finishing my studies in 2016, I have been working in the
                landscaping industry and participated in many workshops, always
                trying to follow new trends and developments.
</p>
              <p>
                Every project is a challenge on its own and has a different
                story to tell. My professional goal is to represent its
                uniqueness and create spaces that are sustainable, functional,
                and aesthetically pleasing.&quot;
</p>
            </div>
              </div>

          </div>


          {/* CONTACT ME SECTION */}
          <div id="contact" className="section justify-content-center contact-container">

            <Img
              className="contact-bg-img-container row"
              style={{}}
              fluid={[
                data.contactBgMobile.childImageSharp.fluid,
                {
                  ...data.contactBgDesktop.childImageSharp.fluid,
                  media: `(min-width: 600px)`,
                },
              ]}
              fadeIn={false}
              alt="Trees and bushes with white and purple flowers in front."
            />

            <div className="contact-bg-dark row"></div>

            <div className="row justify-content-center contact-content">
              <div className="call-to-action col-12 col-sm-5 col-md-4">
                <h2>Have questions? Need to request a quote? <span>Contact us today!</span></h2>
              </div>
              <div id="contact-form-container" className="contact-form col-12 col-sm-5 col-md-4">

                <ContactForm />

              </div>
            </div>

            
          </div>

        </Layout>
      </>
    );
  }
}

export const query = graphql`
  query {
    coverImage0Desktop: file(relativePath: { eq: "background-0.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 600px) 600px,
          (max-width: 800px) 800px,
          (max-width: 1000px) 1000px,
          (max-width: 1200px) 1200px,
          (max-width: 1500px) 1500px,
          (max-width: 2000px) 2000px,
          2000px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverImage0Mobile: file(relativePath: { eq: "background-0-vert.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 300px) 300px,
          (max-width: 600px) 600px,
          1500px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverImage1Desktop: file(relativePath: { eq: "background-1.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 600px) 600px,
          (max-width: 800px) 800px,
          (max-width: 1000px) 1000px,
          (max-width: 1200px) 1200px,
          (max-width: 1500px) 1500px,
          (max-width: 2000px) 2000px,
          2000px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverImage1Mobile: file(relativePath: { eq: "background-1-vert.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 300px) 300px,
          (max-width: 600px) 600px,
          1500px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverImage2Desktop: file(relativePath: { eq: "background-2.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 600px) 600px,
          (max-width: 800px) 800px,
          (max-width: 1000px) 1000px,
          (max-width: 1200px) 1200px,
          (max-width: 1500px) 1500px,
          (max-width: 2000px) 2000px,
          2000px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverImage2Mobile: file(relativePath: { eq: "background-2-vert.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 300px) 300px,
          (max-width: 600px) 600px,
          1500px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    profileImg: file(relativePath: { eq: "about-me.png" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 500px) 300px,
          600px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactBgDesktop: file(relativePath: { eq: "contact-bg-2.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 600px) 600px,
          (max-width: 800px) 800px,
          (max-width: 1000px) 1000px,
          (max-width: 1200px) 1200px,
          (max-width: 1500px) 1500px,
          (max-width: 2000px) 2000px,
          2000px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactBgMobile: file(relativePath: { eq: "contact-bg-2-vert.jpg" }) {
      childImageSharp {
        fluid(sizes: """
          (max-width: 300px) 300px,
          (max-width: 600px) 600px,
          1500px""", 
          quality: 95, fit: COVER
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`;


export default HomePage;
