import React from 'react';
import PropTypes from 'prop-types';
import formField from './FormField';
import { filterProps } from './utils';

const Input = (props) => {
  const { inputType } = props;
  const filteredProps = filterProps(props, Input.propTypes);

  return (
    <input
      type={inputType}
      {...filteredProps}
    />
  );
};

Input.defaultProps = {
  placeholder: '',
  inputType: 'text',
};


// TODO extract common props
Input.propTypes = {
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default formField(Input);
