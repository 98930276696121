import 'whatwg-fetch';

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  }

  return response.json().then((json) => {
    const error = new Error(json.message || 'Oops, something went wrong...');
    return Promise.reject(Object.assign(
      error,
      { response, apiMessage: json.message },
    ));
  });
}

export const getProjects = () => fetch('/api/projects', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
}).then(handleResponse);

export const createMessage = model => fetch('/api/messages', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(model),
}).then(handleResponse);
