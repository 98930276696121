import React from 'react';
import PropTypes from 'prop-types';
import formField from './FormField';
import { filterProps } from './utils';

const TextArea = (props) => {
  const filteredProps = filterProps(props, TextArea.propTypes);

  return (
    <textarea
      type="text"
      {...filteredProps}
    />
  );
};

TextArea.defaultProps = {
  placeholder: '',
  rows: 3,
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default formField(TextArea);
