import Formsy from 'formsy-react';
import React from 'react';
import { createMessage } from 'services/api';
import analytics from 'utils/analytics';
import Input from 'components/Input';
import TextArea from 'components/TextArea';

import './ContactForm.scss';

class ContactForm extends React.Component {
  state = {
    isRequestInProgress: false,
    showSuccessMessage: false,
    serverError: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = (model, resetForm) => {
    analytics.event.message('Send');
    this.setState({
      isRequestInProgress: true,
    }, () => createMessage(model)
      .then(() => {
        analytics.event.message('Send succeeded');
        resetForm();
        this.setState({
          isRequestInProgress: false,
          showSuccessMessage: true,
        });
      })
      .catch((ex) => {
        analytics.event.message('Send failed');
        this.setState({
          serverError: ex.message,
          isRequestInProgress: false,
          showSuccessMessage: false,
        });
        console.log('parsing failed', ex);
      }));
  };

  renderSuccessMessage = () => {
    const successMessage = "Thank you for reaching out! We'll get back to you as soon as possible.";
    return (
      <div className="alert alert-success contactme-message" role="alert">
        {successMessage}
      </div>
    );
  };

  renderError = errorMessage => (
    <div className="alert alert-danger contactme-message" role="alert">
      {errorMessage}
    </div>
  );

  renderSubmit = isRequestInProgress => (isRequestInProgress
    ? (
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
    : <button type="submit" className="primary-button">Get in touch</button>
  );

  render = () => {
    const { showSuccessMessage, serverError, isRequestInProgress } = this.state;
    return (
      <div>
        <Formsy
          id="contact-form"
          ref={(c) => {
            this.form = c;
          }}
          onValidSubmit={this.submit}
        >
          <Input
            id="name"
            name="name"
            label="Name"
            placeholder="Your name"
            validationErrors={{
              isDefaultRequiredValue: 'This field is required.',
            }}
            required
          />
          <Input
            id="email"
            name="email"
            label="Email"
            placeholder="Your email address"
            validations={{
              isEmail: true,
            }}
            validationErrors={{
              isDefaultRequiredValue: 'This field is required.',
              isEmail: 'Please enter a valid email address.',
            }}
            required
          />

          <TextArea
            id="body"
            name="body"
            label="Body"
            placeholder="How can we help?"
            validationErrors={{
              isDefaultRequiredValue: 'This field is required.',
            }}
            rows={5}
            required
          />
          {this.renderSubmit(isRequestInProgress)}
        </Formsy>
        <div className="contactme-message-container">
          {showSuccessMessage && this.renderSuccessMessage()}
          {serverError && this.renderError(serverError)}
        </div>
      </div>
    );
  };
}

export default ContactForm;
