import { withFormsy } from 'formsy-react';
import React from 'react';
import PropTypes from 'prop-types';
import './FormField.scss';

export default (WrappedComponent) => {
  class FormFieldWrapper extends React.Component {
    isInvalid = () => !this.props.isPristine && (this.props.showRequired || !this.props.isValid); // eslint-disable-line

    changeValue = (event) => {
      this.props.setValue(event.currentTarget.value); // eslint-disable-line
    };

    onBlur = (event) => {
      this.props.setValue(event.currentTarget.value); // eslint-disable-line
    };

    render() {
      const { id, label } = this.props;

      const isInvalid = this.isInvalid();
      const errorMessage = !this.props.isPristine ? this.props.errorMessage : null; // eslint-disable-line

      const wrappedProps = {
        ...this.props,
        id,
        className: `form-control${isInvalid ? ' is-invalid' : ''}`,
        value: this.props.value || '', // eslint-disable-line
      };

      return (
        <div className="form-group">
          { label ? <label htmlFor={id}>{label}</label> : null }
          <WrappedComponent
            onChange={this.changeValue}
            onBlur={this.onBlur}
            {...wrappedProps}
          />
          <div className="invalid-message fixed-height">{errorMessage}</div>
        </div>
      );
    }
  }

  FormFieldWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  return withFormsy(FormFieldWrapper);
};
