import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ProjectThumbnail from 'components/ProjectThumbnail/ProjectThumbnail';

const FEATURED_COUNT = 6;

const PortfolioView = () => {
  let { allProject: projects } = useStaticQuery(graphql`
      query {
          allProject {
            edges {
              node {
                id
                name
                slug
                description
                priority
                thumbnailUrl
                thumbnailImg {
                  childImageSharp {
                    fluid(sizes: """
                      (max-width: 300px) 200px,
                      (max-width: 600px) 400px,
                      (max-width: 900px) 600px,
                      (max-width: 1000px) 800px,
                      1500px""", 
                      quality: 95, fit: COVER
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                project_images {
                  url
                }
              }
            }
          }
      }
    `) || [];
  projects = projects.edges.map(p => p.node);
  projects.sort((a, b) => a.priority - b.priority);
  const featuredProjects = projects.length > FEATURED_COUNT ? projects.slice(0, FEATURED_COUNT) : projects;
  return (
    <>
      {
        Array.isArray(featuredProjects) && !featuredProjects.length
          ? <h4>Coming soon...</h4>
          : (featuredProjects || []).filter(
            project => !!project.project_images.length,
          ).map(
            project => <ProjectThumbnail key={project.slug} project={project} />,
          )
      }
    </>
  );
}

export default PortfolioView;
